var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: { dense: "", items: _vm.data, headers: _vm.dataHeader },
            scopedSlots: _vm._u([
              {
                key: "item.Index",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(" " + _vm._s(_vm.data.indexOf(item) + 1) + " "),
                  ]
                },
              },
              {
                key: "top",
                fn: function () {
                  return [
                    _c(
                      "v-toolbar",
                      { attrs: { flat: "", height: "autp" } },
                      [
                        _c(
                          "v-row",
                          { staticClass: "ma-3" },
                          [
                            _c(
                              "v-toolbar-title",
                              { staticClass: "font-weight-bold text-h4 my-3" },
                              [_vm._v(" Claim Reward ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "item.claim_created_at",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.convertTimeZone(item.claim_created_at)) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "item.name",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "PageRewardDetail",
                            params: { id: item.reward_id },
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.name) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "item.status",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "PageClaimRewardDetail",
                            params: { id: item.user_reward_transition_id },
                          },
                        },
                      },
                      [
                        item.status == "Pending"
                          ? _c("span", [_vm._v(" In Progress ")])
                          : _c("span", [
                              _vm._v(" " + _vm._s(item.status) + " "),
                            ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }