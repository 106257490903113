import { render, staticRenderFns } from "./PageClaimRewardList.vue?vue&type=template&id=74bdb6c2&"
import script from "./PageClaimRewardList.vue?vue&type=script&lang=js&"
export * from "./PageClaimRewardList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VContainer,VDataTable,VRow,VSkeletonLoader,VToolbar,VToolbarTitle})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/tp-vision-web1-vuetify2/tp-vision-web1-vuetify2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74bdb6c2')) {
      api.createRecord('74bdb6c2', component.options)
    } else {
      api.reload('74bdb6c2', component.options)
    }
    module.hot.accept("./PageClaimRewardList.vue?vue&type=template&id=74bdb6c2&", function () {
      api.rerender('74bdb6c2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/referral/PageClaimRewardList.vue"
export default component.exports